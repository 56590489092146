<template>
   <v-container style="margin-top:160px;"  fluid>
     <!-- <v-card class="rounded-lg" elevation="10" style="position:fixed;top:80px;left:0;right:0; margin:auto;z-index:150;width:60%;">
          
          <v-card-text   v-if="k_type!=9" style="background-color:#fdaa47;color:white;text-align:center;">
             <span style="font-size:40px;padding:30px;">{{type_k[k_type]}}</span><br>
             <span>Ваш поточний тип контракту </span>
        
         </v-card-text>

      
        
      </v-card> -->
       <!-- <v-chip 
        
        class=""
        style="position:fixed;z-index:10000;right:20px;bottom:20px;font-size:15px;"
        color="orange"
        text-color="white"
        ><center>{{this.skus_selected.length}}</center></v-chip> -->
      <v-card class="rounded-lg" elevation="10">
         <v-form class="sku_sel" @submit.prevent="sku_sel_action">
         
          <v-card-title style="background-color:orange;color:white;z-index:1000;margin-bottom:20px;justify-content: center;">
            <center><b>Завдання-опитування </b><br><font style="font-size:17px;text-align:center;">
              Відзначте за допомогою чекбоксів продукцію Imperial Tobacco, яка наявна у вашій торговій точці. 
              <br>За виконання цього завдання гарантовано нараховується 20 балів.
             </font></center>

          
          </v-card-title>
          <v-card-text>
              <v-row fluid justify="center" v-for="(item,index) in skus_available" :key="index"  >
                <v-col cols="2" style="background-color:#f7efe9">
                  <v-avatar left rounded style="height: 90px !important;  ">
                    <v-img :src="'img/pack_brands/' + item.img" style="height: 90px !important;  width: auto !important;" contain></v-img>
                  </v-avatar>
                  </v-col>
                  <v-col cols="8" style="display:flex; align-items:center;justify-content:center;background-color:#f7efe9"
                  @click.stop="
                                popup_cig_header = item.name;
                                popup_cig_img = item.img;
                                popup_cig_nic = item.desc;
                                popup_cig = true;
                              "
                  
                  
                  ><center>
                  <b style="font-size:18px;">{{ item.name }} </b>
                  <!-- <span  v-if="item.nec=='nec'" style="color:orange;font-size:25px;">*</span> -->
                  
                  </center>
                </v-col>
                <v-col cols="2" style="display:flex; align-items:center;justify-content:center;background-color:#f7efe9">        
                  
                    <v-checkbox v-model="skus_selected"     :value=item.id aria-checked="true" color="#ee6424"  v-if="item.nec=='nec'">
                      
                    </v-checkbox>
                    <v-checkbox v-model="skus_selected"  :value=item.id color="#ee6424" v-if="item.nec=='opt'">
                      
                    </v-checkbox>
                </v-col>
               <v-col cols="12" ><hr></v-col>
              </v-row>


              <v-row fluid justify="center"  >
                <v-col cols="2" style="background-color:#f7efe9">
                  <v-avatar left rounded style="height: 90px !important;  ">
                    <v-img :src="'img/pack_brands/no.png'" style="height: 90px !important;  width: auto !important;" contain></v-img>
                  </v-avatar>
                  </v-col>
                  <v-col cols="8" style="display:flex; align-items:center;justify-content:center;background-color:#f7efe9"
                  
                  
                  
                  ><center>
                  <b style="font-size:18px;">Продукція Imperial Tobacco відсутня</b>
                  
                  
                  </center>
                </v-col>
                <v-col cols="2" style="display:flex; align-items:center;justify-content:center;background-color:#f7efe9">        
                  
                  
                    <v-checkbox v-model="skus_selected" @click="skus_selected=skus_selected.filter(sku => sku == 666);" :value=666 color="#ee6424" >
                      
                    </v-checkbox>
                </v-col>
               <v-col cols="12" ><hr></v-col>
              </v-row>
         </v-card-text>

         <v-card-actions>

           <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button" type="submit">Продовжити</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

         </v-card-actions>
         </v-form>
      </v-card>
        
      <v-dialog v-model="popup_cig">
      <v-card>
        <v-card-title class="headline grey lighten-2"  style="justify-content: center;">
          {{ popup_cig_header }}
        </v-card-title>
        <br>
        <v-img :src="'img/pack_brands/' + popup_cig_img" style="max-height:400px;width:auto !important" contain></v-img>
        <v-card-text>
          <center>{{ popup_cig_nic }} мг.</center>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="popup_cig = false">Закрити</span>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </v-container> 
</template>
<style scoped>
.v-avatar {
  height: 90px !important;
}
.v-card__title {
  word-break: break-word !important;
}
</style>
<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
//import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
    component: "asssel",
  name: "asssel",
  
  //components:{btnsqr}, 
  watch: {
     
  },
  computed:{

    k_type: function () {
      return this.$store.getters.contract;
    },
  },

  data: () => ({
    type_k:['','K-300','K-400','K-500'],
    type_sku_count:['','16','24','32'],
    skus_available:[],
    skus_selected:[],
    popup_cig: false,
    popup_cig_header: "",
    popup_cig_img: "",
    popup_cig_nic: "",
    change_type:0,
  }), 
  methods: {
    select_sku(item){
            this.skus_selected.push(item);            
           
            
            
    },

    sku_sel_action: function(){
     
    

      if(this.skus_selected.length<1)
      {
        this.$emit('pushe', {header:'Помилка',text:'<center><b>Обери мінімум одну відповідь</b>'});
        return;
      }

      axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_temp_task",
          cig: this.skus_selected
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data=='SKU_IMP')
        {
           alert("Оберіть обов'язкові SKU, позначені \"*\"");
        }
        else if(resp.data=='SKU_KERR')
        {
          alert("Оберіть 16/24/32 SKU");
        }
        else
        {
          this.$router.push("Tasks");
          
        }
      });
    }


  },
  created() {
    if (this.$route.params.change_type==1) {
      
      this.change_type=1;
    }
     console.log("PARAM_CHANGE:"+this.$route.params.param_change);
      this.$store.dispatch("profileset");
       this.$store.dispatch("page_title_set", "SKU");
    axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_list_period" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
          this.skus_available=resp.data;  
            console.log(this.skus_available)         
        
      });

      axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_change"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==1)
            {
            this.change_type=1;
            }
           
        
      });











  },
};
</script>